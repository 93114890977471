<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Precios Brent Y TRM</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Comercialización</li>
                  <li class="breadcrumb-item active">Básico</li>
                  <li class="breadcrumb-item active">General</li>
                  <li class="breadcrumb-item active">Precios Brent</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-11"></div>
                  <div class="col-md-1">
                    <div class="btn-group float-right">
                      <!-- SE LLAMA ACTUALIZACIÓN PÁGINA-->
                      <button
                        type="button"
                        class="btn bg-navy"
                        @click="getIndex()"
                      >
                        <i class="fas fa-sync-alt"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-warning"
                        @click="openModal('Crear', null)"
                        v-if="$store.getters.can('com.preciosBrent.create')"
                      >
                        <i class="fas fa-coins"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-success"
                        @click="openModal2('Crear', null)"
                      >
                        <i class="fas fa-file-upload"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card-body -->
              <div class="card-body table-responsive p-0">
                <div class="row m-2">
                  <div class="col-md-6">
                    <label> Precios BRENT</label>
                    <table
                      id="users"
                      class="table table-bordered table-striped table-hover table-sm"
                    >
                      <thead>
                        <tr>
                          <th>
                            Id<input
                              type="number"
                              class="form-control form-control-sm"
                              v-model="filtros.id1"
                              @change="getIndex()"
                            />
                          </th>
                          <th>
                            Precio<input
                              type="number"
                              class="form-control form-control-sm"
                              v-model="filtros.precio1"
                              @change="getIndex()"
                            />
                          </th>
                          <th>
                            Fecha<input
                              type="date"
                              class="form-control form-control-sm"
                              v-model="filtros.fecha1"
                              @change="getIndex()"
                            />
                          </th>
                          <th>
                            Moneda
                            <select
                              v-model="filtros.moneda1"
                              class="form-control form-control-sm"
                              @change="getIndex()"
                            >
                              <option value="1">Dólar Americano</option>
                              <option value="2">Peso Colombiano</option>
                            </select>
                          </th>
                          <th>Accion</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="precio in precios.data" :key="precio.id">
                          <td style="width: 150px">{{ precio.id }}</td>
                          <td>{{ precio.precio }}</td>
                          <td>{{ precio.fecha }}</td>
                          <td>{{ precio.Moneda }}</td>
                          <td style="width: 50px">
                            <div class="btn-group float-right">
                              <button
                                type="button"
                                class="btn btn-sm bg-navy"
                                @click="openModal('Editar', precio)"
                                v-if="
                                  $store.getters.can('com.preciosBrent.create')
                                "
                              >
                                <i class="fas fa-edit"></i>
                              </button>
                              <button
                                type="button"
                                class="btn btn-sm btn-danger"
                                @click="destroy(precio.id)"
                                v-if="
                                  $store.getters.can('com.preciosBrent.delete')
                                "
                              >
                                <i class="fas fa-trash-alt"></i>
                              </button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="col-md-6">
                    <label> TRM</label>
                    <table
                      id="users"
                      class="table table-bordered table-striped table-hover table-sm"
                    >
                      <thead>
                        <tr>
                          <th>
                            Id<input
                              type="number"
                              class="form-control form-control-sm"
                              v-model="filtros.id2"
                              @change="getIndex()"
                            />
                          </th>
                          <th>
                            Precio<input
                              type="number"
                              class="form-control form-control-sm"
                              v-model="filtros.precio2"
                              @change="getIndex()"
                            />
                          </th>
                          <th>
                            Fecha<input
                              type="date"
                              class="form-control form-control-sm"
                              v-model="filtros.fecha2"
                              @change="getIndex()"
                            />
                          </th>
                          <th>
                            Moneda
                            <select
                              v-model="filtros.moneda2"
                              class="form-control form-control-sm"
                              @change="getIndex()"
                            >
                              <option value="1">Dólar Americano</option>
                              <option value="2">Peso Colombiano</option>
                            </select>
                          </th>
                          <th>Accion</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="precio in trm.data" :key="precio.id">
                          <td style="width: 150px">{{ precio.id }}</td>
                          <td>{{ precio.precio }}</td>
                          <td>{{ precio.fecha }}</td>
                          <td>{{ precio.Moneda }}</td>
                          <td style="width: 50px">
                            <div class="btn-group float-right">
                              <button
                                type="button"
                                class="btn btn-sm bg-navy"
                                @click="openModal('Editar', precio)"
                                v-if="
                                  $store.getters.can('com.preciosBrent.create')
                                "
                              >
                                <i class="fas fa-edit"></i>
                              </button>
                              <button
                                type="button"
                                class="btn btn-sm btn-danger"
                                @click="destroy(precio.id)"
                                v-if="
                                  $store.getters.can('com.preciosBrent.delete')
                                "
                              >
                                <i class="fas fa-trash-alt"></i>
                              </button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="modal" ref="precioModal" tabindex="-1" role="dialog">
                <div class="modal-dialog modal-lg p-0 pt-1" role="document">
                  <div class="modal-content">
                    <div class="modal-header bg-frontera-top-left pt-2 pb-2">
                      <h5 class="modal-title text-white">
                        {{ modal.accion }}Precio
                      </h5>
                      <button
                        type="button"
                        class="close text-white"
                        @click="limpiarModal()"
                        data-dismiss="modal"
                        aria-label="Close"
                        id="close-modal"
                        ref="closeModal"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <div class="form-group">
                        <div class="row">
                          <div class="col-md-6">
                            <label for="precio">Moneda:</label>
                            <select
                              v-model="nuevoPrecio.moneda"
                              class="form-control form-control"
                              :class="[
                                $v.nuevoPrecio.moneda.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid',
                                $store.getters.getDarkMode
                                  ? 'dark-vselect'
                                  : '',
                              ]"
                            >
                              <option value="">Seleccione...</option>
                              <option
                                v-for="moneda in listasForms.monedas"
                                :key="moneda.numeracion"
                                :value="moneda.numeracion"
                              >
                                {{ moneda.descripcion }}
                              </option>
                            </select>
                          </div>
                          <div class="col-md-6">
                            <label for="precio">Precio:</label>
                            <input
                              type="number"
                              class="form-control"
                              id="precio"
                              v-model="nuevoPrecio.precio"
                              :class="
                                $v.nuevoPrecio.precio.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                            />
                          </div>
                          <div class="col-md-6">
                            <label for="fecha">Fecha:</label>
                            <input
                              type="date"
                              class="form-control"
                              id="fecha"
                              v-model="nuevoPrecio.fecha"
                              :class="
                                $v.nuevoPrecio.fecha.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button
                        @click="limpiarModal()"
                        data-dismiss="modal"
                        aria-label="Close"
                        id="close-modal"
                        ref="closeModal"
                        type="button"
                        class="btn btn-secondary"
                      >
                        Cerrar
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary"
                        @click="save()"
                      >
                        Guardar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal" ref="importModal" tabindex="-1" role="dialog">
                <div class="modal-dialog modal-lg p-0 pt-1" role="document">
                  <div class="modal-content">
                    <div class="modal-header bg-frontera-top-left pt-2 pb-2">
                      <h5 class="modal-title text-white">Plantilla Precios</h5>
                      <button
                        type="button"
                        class="close text-white"
                        @click="limpiarModal2()"
                        data-dismiss="modal2"
                        aria-label="Close"
                        id="close-modal2"
                        ref="closeModal2"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <div class="row">
                        <div class="btn-group float-right">
                          <button
                            type="button"
                            class="btn btn-link btn-sm"
                            @click="exportarDoc()"
                          >
                            Descargar Plantilla
                            <i class="fas fa-download"></i>
                          </button>
                        </div>
                        <div
                          class="btn btn-default image-preview-input col-md-10"
                        >
                          <input
                            type="file"
                            id="file"
                            ref="file"
                            class="form-control btn btn-light"
                            accept=".XLSX, .CSV"
                            v-on:change="elegirDirectorio()"
                            :class="
                              $v.form.file.$invalid ? 'is-invalid' : 'is-valid'
                            "
                          />
                        </div>
                        <div class="form-group col-md-2 float-left">
                          <button
                            type="button"
                            class="btn btn-secondary"
                            @click="limpiarArchivo()"
                          >
                            <i class="fas fa-trash"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <div class="row">
                        <button
                          type="button"
                          class="btn btn-primary"
                          @click="importExcel()"
                        >
                          Importar
                          <i class="fas fa-upload"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="precios.total">
                  <p>
                    Mostrando del <b>{{ precios.from }}</b> al
                    <b>{{ precios.to }}</b> de un total:
                    <b>{{ precios.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  :data="precios"
                  @pagination-change-page="getIndex"
                  :limit="5"
                  align="right"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Loading from "../../../../components/Loading";
import { required, minLength } from "vuelidate/lib/validators";
export default {
  name: "PreciosBrent",
  components: {
    pagination,
    Loading,
  },
  data() {
    return {
      cargando: false,
      modal: [],
      filtros: {},
      precios: {},
      trm: {},
      listasForms: {
        monedas: [],
      },
      form: {
        file: null,
      },
      nuevoPrecio: {
        fecha: "",
        precio: null,
        moneda: "",
      },

      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },
  validations: {
    nuevoPrecio: {
      fecha: {
        required,
      },
      precio: {
        required,
      },
      moneda: {
        required,
      },
    },
    form: {
      file: {
        required,
      },
    },
  },
  methods: {
    getIndex(page = 1) {
      this.cargando = true;
      axios
        .get("/api/com/precios?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          console.log(response);
          this.precios = response.data.preciosBrent;
          this.trm = response.data.trm;
          this.cargando = false;
        });
    },

    // Método para abrir la modal
    openModal(accion, precio) {
      this.modal.accion = accion;
      if (accion == "Editar") {
        this.nuevoPrecio = {
          fecha: precio.fecha,
          precio: precio.precio,
          moneda: precio.moneda,
        };
        this.modal.id = precio.id;
      } else {
        this.nuevoPrecio = {
          fecha: "",
          precio: null,
          moneda: "",
        };
        this.modal.id = null;
      }
      this.$refs.precioModal.classList.add("show");
      this.$refs.precioModal.setAttribute("style", "display: block");
    },

    openModal2(accion, precio) {
      this.modal.accion = accion;
      if (accion == "Editar") {
        this.nuevoPrecio = {
          fecha: precio.fecha,
          precio: precio.precio,
          moneda: precio.moneda,
        };
        this.modal.id = precio.id;
      } else {
        this.nuevoPrecio = {
          fecha: "",
          precio: null,
          moneda: "",
        };
        this.modal.id = null;
      }
      this.$refs.importModal.classList.add("show");
      this.$refs.importModal.setAttribute("style", "display: block");
    },

    // Método para cerrar la modal y limpiar el formulario
    limpiarModal() {
      this.$refs.precioModal.classList.remove("show");
      this.$refs.precioModal.removeAttribute("style");
      this.nuevoPrecio = {
        fecha: "",
        precio: null,
        moneda: "",
      };
    },
    limpiarModal2() {
      this.$refs.importModal.classList.remove("show");
      this.$refs.importModal.removeAttribute("style");
      this.nuevoPrecio = {
        fecha: "",
        precio: null,
        moneda: "",
      };
    },
    save() {
      //     if (!this.nuevoPrecio.$invalid) {
      let data = {
        id: this.modal.id,
        fecha: this.nuevoPrecio.fecha,
        precio: this.nuevoPrecio.precio,
        moneda: this.nuevoPrecio.moneda,
      };
      this.cargando = true;
      axios({
        method: "PUT",
        url: "/api/com/precios",
        data: data,
      })
        .then((response) => {
          this.$refs.closeModal.click();
          this.nuevoPrecio = {};
          this.getIndex(this.page);
          this.$swal({
            icon: "success",
            title: "Se guardo exitosamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error, vualeva a intentarlo..." + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
      // }
    },

    exportarDoc() {
      //this.$parent.cargando = true;
      axios({
        method: "get",
        url: "/api/com/precios/export",
      })
        .then((response) => {
          let data = response.data;
          //this.$parent.cargando = false;
          location.href = data.url;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error inesperado " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          //this.$parent.cargando = false;
        });
    },
    elegirDirectorio() {
      this.form.file = this.$refs.file.files[0];
    },

    importExcel() {
      this.cargando = true;
      let formData = new FormData();
      formData.append("file", this.form.file);
      axios
        .post("/api/com/precios/importPreciosPlantilla", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          console.log(res);
          if (res.data.status == 0) {
            this.$swal({
              icon: "error",
              title: res.data.message,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.cargando = false;
          } else {
            this.limpiarModal();
            this.getIndex();
            this.$refs.closeModal2.click();
            this.$swal({
              icon: "success",
              title: "Los precios se guardaron exitosamente.",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.cargando = false;
          }
        });
    },

    // Método para guardar el nuevo precio
    guardarPrecio() {
      if (
        this.nuevoPrecio.fecha &&
        this.nuevoPrecio.precio &&
        this.nuevoPrecio.moneda
      ) {
        this.limpiarModal();
        this.getIndex();
      } else {
        alert("Por favor, completa todos los campos.");
      }
    },

    limpiarArchivo() {
      this.form.file = null;
      document.getElementById("file").value = "";
    },

    destroy($id) {
      this.$swal({
        title: "Esta seguro de eliminar este Item?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          axios.delete("/api/com/precios/" + $id).then((response) => {
            this.getIndex();
            this.$swal({
              icon: "success",
              title: "Se elimino el item exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
        }
      });
    },

    getMonedas() {
      axios.get("/api/lista/134").then((response) => {
        this.listasForms.monedas = response.data;
      });
    },
  },

  mounted() {
    this.getIndex();
    this.getMonedas();
  },
};
</script>
